body,
textarea {
  /* background-color: #fcfdff; */
  margin: 0;
  /* text-align: justify; */
  font-family: Cantarell, 'SourceHanSerifCN';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #40464f;
}

code {
  font-family: 'JetBrains Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: #d7dde7af;
}

::-webkit-scrollbar {
  width: .6rem !important;
}

::selection {
  background: #c8d2e183;
}